<template>
  <div class="filter-item">
    <div class="title">{{ item.title }}</div>
    <Search
      @setSearchText="setSearchText"
      :placeholder="placeholders[item.title]"
      :reset="reset"
      :global="false"
    />
    <q-list bordered separator>
      <q-item clickable v-ripple v-for="el in list" :key="el.id">
        <q-checkbox v-model="selected" :val="el.id" :label="el.name" />
      </q-item>
    </q-list>
  </div>
</template>

<script>
import Search from '@/components/global/Search.vue'

export default {
  name: 'FilterItem',
  components: {
    Search
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    searchStatus: {
      type: Boolean,
      default: false
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: this.item.list,
      placeholders: {
        Intitulés: 'Intitulé',
        Entités: 'Rechercher une entité',
        Sources: 'Source',
        Années: 'Année'
      },
      reset: false,
      selected: []
    }
  },
  methods: {
    setSearchText(val) {
      this.list = this.item.list.filter(
        item => item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
      )

      this.$emit('resetSearch', this.item.key)
    }
  },
  watch: {
    isClear: {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.selected = []
        }
      }
    },
    selected: function() {
      this.$emit('selected', this.selected)
    },
    searchStatus: function(status) {
      this.reset = status
    },
    'item.list': function(list) {
      this.list = list
    }
  }
}
</script>
