import { render, staticRenderFns } from "./FilterItem.vue?vue&type=template&id=5e48c10c&"
import script from "./FilterItem.vue?vue&type=script&lang=js&"
export * from "./FilterItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QCheckbox});qInstall(component, 'directives', {Ripple});
