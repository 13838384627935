<template>
  <div class="filter-container">
    <fragment v-if="!getFetchingFilters">
      <q-icon name="close" @click="closeFilter" class="top-close-icon" />
      <div class="top">
        <FilterItem
          v-if="filterData.filterDataCodes"
          :item="filterData.filterDataCodes"
          :search-status="search.filterDataCodes"
          @resetSearch="resetSearch"
          @selected="val => selectedFilter('filterDataCodes', val)"
          :isClear="isClear"
        />
        <FilterItem
          v-if="filterData.filterDataEntityName"
          :item="filterData.filterDataEntityName"
          :search-status="search.filterDataEntityName"
          @resetSearch="resetSearch"
          @selected="val => selectedFilter('filterDataEntityName', val)"
          :isClear="isClear"
        />
        <FilterItem
          v-if="filterData.filterDataSources"
          :item="filterData.filterDataSources"
          :search-status="search.filterDataSources"
          @resetSearch="resetSearch"
          @selected="val => selectedFilter('filterDataSources', val)"
          :isClear="isClear"
        />
        <FilterItem
          v-if="filterData.filterDataDocumentYears"
          :item="filterData.filterDataDocumentYears"
          :search-status="search.filterDataDocumentYears"
          @resetSearch="resetSearch"
          @selected="val => selectedFilter('filterDataDocumentYears', val)"
          :isClear="isClear"
        />
      </div>
      <div class="bottom">
        <div class="left">
          <q-toggle
            v-for="(toggle, i) in filterData.filterDataConfidences"
            :key="i"
            :class="i"
            v-model="filterData.filterDataConfidences[i].status"
            :label="filterData.filterDataConfidences[i].label"
            left-label
          />
        </div>
        <div class="right bottom-buttons">
          <q-btn
            label="Supprimer les filtres"
            class="btn-white"
            @click="clearFilter"
          >
            <q-icon name="close" />
          </q-btn>
          <!-- <q-btn
            label="Voir les résultats"
            icon-right="trending_flat"
            @click="selectedFilter"
          /> -->
        </div>
      </div>
    </fragment>
    <LoadingOrEmpty v-else :loading="getFetchingFilters" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import FilterItem from '@/components/project/FilterItem.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  name: 'FilterContainer',
  props: {
    filter: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoadingOrEmpty,
    FilterItem
  },
  data() {
    return {
      filterData: {},
      search: {
        filterDataCodes: false,
        filterDataSources: false,
        filterDataDocumentYears: false,
        filterDataEntityName: false
      },
      delay: false,
      selectedFilters: {
        filterDataCodes: [],
        filterDataConfidences: [],
        filterDataDocumentYears: [],
        filterDataSources: [],
        nextToken: {}
      },
      entityNamesFilers: [],
      isClear: false
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getFilterDataCodes',
      'getErrorFetchingFilters',
      'getFilterDataSources',
      'getFilterDataDocumentYears',
      'getFilterDataEntityName',
      'getFilterDataConfidences',
      'getFilter',
      'getEntitiesProject',
      'getEntitiesProjectIdsList',
      'getFetchingFilters',
      'getFilterHandlerUpdateStatus',
      'getFetchAllData',
      'getOpenEntities'
    ])
  },
  methods: {
    ...mapActions('projects', [
      'fetchFiltersData',
      'updateFilter',
      'resetEntitiesProject',
      'fetchEntitiesData',
      'endLoadingEntitiesProject',
      'fetchEntitiesProject',
      'setFilterHandlerUpdateStatus',
      'fetchAllData',
      'closeEntities',
      'openEntities'
    ]),
    fetchData(isOpening = false) {
      this.fetchFiltersData(this.$route.params.projectId).then(() => {
        Vue.set(this.filterData, 'filterDataCodes', {
          title: 'Intitulés',
          placeholder: 'Intitulés',
          list: this.getFilterDataCodes,
          key: 'filterDataCodes'
        })
        Vue.set(this.filterData, 'filterDataSources', {
          title: 'Sources',
          placeholder: 'Source',
          list: this.getFilterDataSources,
          key: 'filterDataSources'
        })
        Vue.set(this.filterData, 'filterDataDocumentYears', {
          title: 'Années',
          placeholder: 'Années',
          list: this.getFilterDataDocumentYears,
          key: 'filterDataDocumentYears'
        })
        Vue.set(this.filterData, 'filterDataEntityName', {
          title: 'Entités',
          placeholder: 'Rechercher une entité',
          list: this.getFilterDataEntityName,
          key: 'filterDataEntityName'
        })
        Vue.set(this.filterData, 'filterDataConfidences', {
          ...this.getFilterDataConfidences
        })

        this.setFilterHandlerUpdateStatus({ status: false })
      })

      if (isOpening) this.delay = false
    },
    closeFilter() {
      this.clearFilter()
      this.$emit('closeFilter')
      this.closeEntities({ status: true })
      // setTimeout(() => this.closeEntities({ status: false }), 200)
    },
    clearFilter() {
      this.delay = true
      this.selectedFilters = {
        filterDataCodes: [],
        filterDataConfidences: [],
        filterDataDocumentYears: [],
        filterDataSources: [],
        nextToken: {}
      }
      this.fetchAllData({ status: false })
      this.closeEntities({ status: true })
      this.updateFilter({ filter: this.selectedFilters })
      this.isClear = true

      setTimeout(() => {
        this.delay = false
        this.isClear = false
        this.closeEntities({ status: false })
      }, 0)
    },
    resetSearch(key) {
      this.search[key] = false
    },

    async selectedFilter(type, values) {
      if (type !== 'filterDataEntityName') {
        this.selectedFilters[type] = values
      }
      let entityNames = [...this.entityNamesFilers]
      if (type === 'filterDataEntityName') {
        entityNames = values
        this.entityNamesFilers = values
      }

      this.fetchEntitiesProject({
        projectId: this.$route.params.projectId,
        entitiesId: entityNames.length
          ? entityNames
          : this.getEntitiesProjectIdsList
      })

      if (
        !this.selectedFilters.filterDataCodes.length &&
        !this.selectedFilters.filterDataConfidences.length &&
        !this.selectedFilters.filterDataDocumentYears.length &&
        !this.selectedFilters.filterDataSources.length &&
        !entityNames.length
      ) {
        this.clearFilter()
        this.closeEntities({ status: true })
        return
      }
      // if selected entities name - we show only selected
      this.updateFilter({ filter: this.selectedFilters })
      if (entityNames.length) {
        await this.fetchEntitiesDataMethod(entityNames)
      } else {
        // if not selected we show all entities
        if (this.getFetchAllData) {
          await this.fetchEntitiesDataMethod(this.getEntitiesProjectIdsList)
          this.openEntities({ status: true})
          setTimeout(() => this.openEntities({ status: false }), 200)
        } else {
          await this.fetchEntitiesDataMethod(this.getEntitiesProjectIdsList)
          this.openEntities({ status: true})
          setTimeout(() => this.openEntities({ status: false }), 200)
        }
      }
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    getIdFromList(arr) {
      return arr.filter(item => item.selected === true).map(item => item.id)
    },
    setSelectedFalseInList(arr) {
      arr = arr.map(item => (item.selected = false))
      return arr
    },
    fetchEntitiesDataMethod(arr) {
      arr.forEach((item, index) => {
        this.fetchEntitiesData({
          entityId: item.entityId || item,
          filter: this.getFilter
        })

        if (index === arr.length - 1) {
          this.endLoadingEntitiesProject()
        }
      })
    }
  },
  watch: {
    getErrorFetchingFilters: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    filter: function(status) {
      if (status) {
        this.delay = true
        this.fetchData(true)
      }
    },
    getFilterHandlerUpdateStatus: function(status) {
      if (status) {
        this.delay = true
        this.fetchData(true)
      }
    }
  }
}
</script>
